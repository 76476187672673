@tailwind base;
@tailwind components;
@tailwind utilities;




@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 221.2 83.2% 53.3%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 214, 95%, 93%; /* Lighter version of muted color */
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 221.2 83.2% 53.3%;
    --radius: 1rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 217.2 91.2% 59.8%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 224.3 76.3% 48%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}



:root {
  --navbar-height: 64px;
  --product-header-height: 84px;
  --product-tabs-height: 40px;
}


@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}


body {
  margin: 0;
  font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.glow-bg-1 {
  background-color: #4158D0;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);

}

.glow-bg-2 {
  transform-origin: center center;
  background-image: linear-gradient(to right top, #6c22bd, #8b25bb, #a52bb9, #bc34b7, #d040b5, #e645a5, #f55195, #ff6188, #ff796e, #ff965b, #f5b255, #e1cd60);
  position: absolute;
  left: 50%;
  border-radius: 20px;
  transform: skewY(-15deg) translate(-50%, -50%) scale(.7) translateZ(0);
  pointer-events: none;
  width: 900px;
  height: 500px;
  top: 200px;
  left: 20%;
  filter: blur(100px);
}

.chat-section {
  height: calc(100vh - 104px)
}

.chat-list {
  height: calc(100vh - 244px)
}

.gradient-bg-1 {
  background: #aa4b6b;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #3b8d99, #6b6b83, #aa4b6b);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #3b8d99, #6b6b83, #aa4b6b); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.main-area-gradient {

}

.jumbotron-gradient {
  background-image: linear-gradient(106.89deg, rgba(192, 132, 252, 0.11) 15.73%, rgba(14, 165, 233, 0.41) 15.74%, rgba(232, 121, 249, 0.26) 56.49%, rgba(79, 70, 229, 0.4) 115.91%);
}

.brand-text-bg {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-color: #FF3CAC;
  background-image: linear-gradient(106.89deg, rgba(192, 132, 252, 0.11) 15.73%, rgba(14, 165, 233, 0.41) 15.74%, rgba(232, 121, 249, 0.26) 56.49%, rgba(79, 70, 229, 0.4) 115.91%);
}

.card-gradient {
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes typewriter {
  from { width: 0; }
  to { width: 100%; }
}

.typewriter {
  overflow: hidden;
  /* border-right: .15em solid black;  */
  white-space: nowrap;
  /* margin: 0 auto; */
  animation: typewriter 4s steps(30, end), blink-caret .75s step-end infinite;
}

.main-area-section-h {
  height: calc(100vh - 72px);
}

.main-area-section-w {
  width: calc(100vw - 48px);
  max-width: calc(100vw - 48px);
  min-width: calc(100vw - 48px);
}

.main-area-section-content {
  max-height: calc(100vh - 112px);
  height: calc(100vh - 164px);
}

.bn-container[data-theming-css-variables-demo][data-color-scheme] {
  --bn-colors-editor-text: hsl(#222222);
  --bn-colors-editor-background: hsl(#ffeeee);
  --bn-colors-menu-text: hsl(#ffffff);
  --bn-colors-menu-text: #ffffff;
  --bn-colors-menu-background: hsl(222.2 84% 4.9%);
  --bn-colors-tooltip-text: #ffffff;
  --bn-colors-tooltip-background: hsl(222.2 84% 4.9%);
  --bn-colors-hovered-text: #ffffff;
  --bn-colors-hovered-background: hsl(222.2 84% 4.9%);
  --bn-colors-selected-text: #ffffff;
  --bn-colors-selected-background: hsl(222.2 84% 4.9%);
  --bn-colors-disabled-text: #ffffff;
  --bn-colors-disabled-background: hsl(217.2 32.6% 17.5%);
}
 
/* Changes for dark mode */
.bn-container[data-theming-css-variables-demo][data-color-scheme="dark"] {
  --bn-colors-editor-text: #ffffff;
  --bn-colors-editor-background: hsl(222.2 84% 4.9%);
  --bn-colors-side-menu: hsl(222.2 84% 4.9%);
  --bn-colors-menu-text: #ffffff;
  --bn-colors-menu-background: hsl(222.2 84% 4.9%);
  --bn-colors-tooltip-text: #ffffff;
  --bn-colors-tooltip-background: hsl(222.2 84% 4.9%);
  --bn-colors-hovered-text: #ffffff;
  --bn-colors-hovered-background: hsl(222.2 84% 4.9%);
  --bn-colors-selected-text: #ffffff;
  --bn-colors-selected-background: hsl(222.2 84% 4.9%);
  --bn-colors-disabled-text: #ffffff;
  --bn-colors-disabled-background: hsl(217.2 32.6% 17.5%);
}


.product-view_tab-content {
  height: calc(100vh - 272px);
  max-height: calc(100vh - 272px);
  overflow-y: auto;
}

.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.drop-area {
  border: 2px dashed #007bff;
  border-radius: 10px;
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  padding: 2rem;
}

.drop-area:hover {
  background-color: #f0f8ff;
}

.upload-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.upload-button:hover {
  background-color: #0056b3;
}

.file-format-message {
  margin-top: 10px;
  font-size: 0.9em;
  color: #555;
}

.file-preview {
  margin-top: 10px;
  text-align: center;
}

.preview-image {
  max-width: 100%;
  max-height: 200px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
}